<template>
  <component-wrapper>
    <grid-container-fi :container="false">
      <div
        :class="{
          'teaser-background-image-fi': true,
          'fi-col-span-full': true,
          'teaser-background-image-fi--variant-left': fields?.variantLeft?.value,
        }"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="isEditMode"
          class="teaser-background-image-fi__img teaser-background-image-fi__img--xpe"
          v-html="fields.image.editable"
        />
        <ssr-cloud-image-fi
          v-else
          class="teaser-background-image-fi__img"
          :src="fields?.image?.value?.src"
          :alt="fields.imageAlt?.value || fields.image?.value?.alt"
          :breakpoints="[
            {
              mediaWidth: 479,
              imageWidth: 375,
              imageHeight: 225,
            },
            {
              mediaWidth: 767,
              imageWidth: 480,
              imageHeight: 251,
            },
            {
              mediaWidth: 1023,
              imageWidth: 768,
              imageHeight: 432,
            },
            {
              mediaWidth: 1799,
              imageWidth: 1024,
              imageHeight: 460,
            },
          ]"
          :unlimited="1920"
          :unlimited-height="630"
          width="1920"
          height="630"
          params="org_if_sml=0"
        />
        <a
          v-if="!isEditMode"
          class="teaser-background-image-fi__tile fi-text-size-df-18"
          :href="fields?.link?.value?.href"
          :target="fields?.link?.value?.target"
        >
          <span
            v-if="fields.badge?.value"
            class="teaser-background-image-fi__badge badge-fi"
          >
            {{ fields.badge.value }}
          </span>
          <headline-fi
            v-if="fields.headline?.value"
            :class="{
              'fi-semibold': true,
              'teaser-background-image-fi__headline': true,
            }"
          >
            {{ fields.headline.value }}
          </headline-fi>
          <span
            v-if="fields.link?.value?.text"
            class="teaser-background-image-fi__content"
          >
            {{ fields.link.value.text }}
          </span>
          <div>
            <arrow-icon class="teaser-background-image-fi__arrow-icon fi-text-24 lg:fi-text-30" />
          </div>
        </a>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-else
          class="teaser-background-image-fi__tile fi-text-size-df-18"
        >
          <span
            class="teaser-background-image-fi__badge badge-fi"
            v-html="fields.badge.editable"
          />
          <headline-fi
            :class="{
              'fi-semibold': true,
              'teaser-background-image-fi__headline': true,
            }"
          >
            <span v-html="fields.headline.editable" />
          </headline-fi>
          <span
            class="teaser-background-image-fi__content"
            v-html="fields.link.editable"
          />
          <div>
            <arrow-icon class="teaser-background-image-fi__arrow-icon fi-text-24 lg:fi-text-30" />
          </div>
        </div>
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </grid-container-fi>
  </component-wrapper>
</template>

<script>
import { ArrowIcon, HeadlineFi, SsrCloudImageFi } from 'atoms';
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper';
import GridContainerFi from 'components/grid-fi/GridContainerFi';
import ssrOnlyMixin from 'mixins/ssrOnlyMixin';

export default {
  name: 'TeaserBackgroundImageFi',
  components: {
    ArrowIcon,
    HeadlineFi,
    ComponentWrapper,
    GridContainerFi,
    SsrCloudImageFi,
  },
  mixins: [ssrOnlyMixin],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="scss">
@import './teaser-background-image-fi';
</style>

<style lang="scss">
@import './teaser-background-image-fi-unscoped';
</style>
